body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.beer-card.card {
  display: flex;
  flex-direction: row;
  transition: ease 400ms;
}

.beer-card.card:hover {
  background-color: #f3f8fd;
  cursor: pointer;
  box-shadow: unset !important;
}

.beer-card.card > img {
  max-height: 150px;
  object-fit: contain;
  /* max-width: 150px; */
  flex-basis: 30%;
}
.beer-card.card > .card-body {
  flex-basis: 70%;
}

.beer-card.card .card-subtitle {
  color: #d29825!important;
}

@media (max-width: 768px) {
  .beer-card.card > img {
    flex-basis: 30%;
  }

  .beer-card.card > .card-body {
    flex-basis: 70%;
  }
}

.tabs-container {
  position: relative;
}

.tabs-container .beers-tab li.nav-item,
.tabs-container .beers-tab {
  border: unset !important;
}

.tabs-container .beers-tab li button {
  border: unset !important;
  padding-left: 0px;
}

.tabs-container .beers-tab li button.nav-link {
  color: #969696;
}

.tabs-container .beers-tab li button.nav-link.active {
  font-weight: 700 !important;
  color: black;
}

.tab-action {
  position: absolute;
  top: 0px;
  right: 0px;
}

.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}

.empty-placeholder {
  min-height: 80vh;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.add-beer-image {
  max-height: 100px;
}
